import { Controller } from "@hotwired/stimulus";
import { validateFiles } from "./file_validation";
export default class extends Controller {
  static targets = ["file", "template", "submit", "list", "generalError", "wrapper", "button"];

  connect() {
    this.initDragAndDrop();
    this.addEventListeners();
    this.setConstraints();
    this.acceptedFormats = this.getAcceptedFormats();
    this.filesArray = [];
  }

  setConstraints() {
    const { dataset } = this.submitTarget;
    this.maxFiles = parseInt(dataset.maxFiles) || 15;
    this.maxTotalSize = parseInt(dataset.maxTotalSize) || null;
    this.maxFileSize = parseInt(dataset.maxFileSize) || 15 * 1024 * 1024;
    this.minWidth = parseInt(dataset.minWidth) || null;
    this.minHeight = parseInt(dataset.minHeight) || null;
  }

  getAcceptedFormats() {
    const accept = this.fileTarget.getAttribute("accept");
    return accept ? accept.split(",").map((format) => format.trim()) : [];
  }

  addEventListeners() {
    this.fileTarget.addEventListener("fileUploadError", this.handleErrorEvent.bind(this));
  }

  handleErrorEvent(event) {
    this.wrapperTarget.classList.add("error");
    this.errorTarget.textContent = event.detail.message;
  }

  initDragAndDrop() {
    const dragAndDropEvents = ["dragenter", "dragover", "dragleave", "drop"];
    const dragEvents = ["dragenter", "dragover"];
    const dropEvents = ["dragleave", "drop"];

    dragAndDropEvents.forEach((eventName) => {
      this.wrapperTarget.addEventListener(eventName, this.preventDefaults.bind(this));
    });

    dragEvents.forEach((eventName) => {
      this.wrapperTarget.addEventListener(eventName, this.highlight.bind(this));
    });

    dropEvents.forEach((eventName) => {
      this.wrapperTarget.addEventListener(eventName, this.unhighlight.bind(this));
    });

    this.wrapperTarget.addEventListener("drop", this.handleDrop.bind(this));
  }

  preventDefaults(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  async validateFiles() {
    const { fileErrors, generalError } = await validateFiles(
      this.fileTarget.files,
      this.maxFiles,
      this.maxTotalSize,
      this.maxFileSize,
      this.acceptedFormats,
      this.minWidth,
      this.minHeight
    );

    this.handleErrors(generalError, fileErrors);
    this.sendFileChangeEvent();
  }

  handleErrors(generalError, fileErrors) {
    if (generalError || fileErrors.length > 0) {
      if (generalError) {
        this.wrapperTarget.classList.add("error");
        this.generalErrorTarget.textContent = generalError;
      }

      if (fileErrors.length > 0) {
        fileErrors.forEach((error) => {
          Array.from(this.listTarget.children).find((item) => {
            const fileToError = this.getFileIdentifier(item);
            if (fileToError.startsWith(error.file.name)) {
              let fileError = item.querySelector(".file-error");

              fileError.textContent = error.reason;
              fileError.classList.remove("hidden");

              this.updateFileInput(fileToError);
            }
          });
        });
      }
    } else {
      this.wrapperTarget.classList.remove("error");
    }
  }

  sendFileChangeEvent() {
    const event = new CustomEvent("file_upload:change", { bubbles: true });
    this.element.dispatchEvent(event);
  }

  getFileIdentifier(file) {
    let fileName = file.querySelector(".file-name").textContent;
    fileName = fileName.replace(/\.\.\.$/, "");
    return fileName;
  }

  highlight() {
    this.wrapperTarget.classList.add("dragover");
  }

  unhighlight() {
    this.wrapperTarget.classList.remove("dragover");
  }

  async handleDrop(event) {
    const dataTransferFiles = event.dataTransfer.files;
    this.fileTarget.files = dataTransferFiles;
    await this.updateFileList();
  }

  async updateFileList() {
    const newFiles = Array.from(this.fileTarget.files);

    if (this.maxFiles === 1) {
      this.filesArray = newFiles;
    } else {
      this.filesArray = this.filesArray.concat(newFiles);
    }

    const dataTransfer = new DataTransfer();
    this.filesArray.forEach((file) => dataTransfer.items.add(file));
    this.fileTarget.files = dataTransfer.files;

    this.listTarget.classList.remove("hidden");
    if (this.hasTemplateTarget) this.templateTarget.classList.remove("hidden");

    this.populateFileItem(this.fileTarget.files);

    if (this.hasTemplateTarget) this.templateTarget.classList.add("hidden");

    await this.validateFiles();
  }

  populateFileItem(files) {
    this.emptyFileList();

    Array.from(files).forEach((file) => {
      const clone = this.templateTarget.cloneNode(true);

      clone.classList.remove("file-item-template");
      clone.classList.add("file-item");
      clone.style.display = "flex";

      clone.querySelector(".file-name").textContent =
        file.name.length > 100 ? file.name.substring(0, 97) + "..." : file.name;
      clone.querySelector(".file-size").textContent = `${(file.size / (1024 * 1024)).toFixed(
        2
      )} MB`;

      this.listTarget.appendChild(clone);
    });
  }

  emptyFileList() {
    const fileItems = Array.from(this.listTarget.children);
    const nonTemplateItems = fileItems.filter((item) => item.classList.contains("file-item"));

    nonTemplateItems.forEach((item) => item.remove());
  }

  removeFile(event) {
    const button = event.target;
    const fileToRemove = button.closest(".file-item");
    const fileNameToRemove = this.getFileIdentifier(fileToRemove);

    this.updateFileInput(fileNameToRemove);
    fileToRemove.remove();

    this.validateFiles();
  }

  removeAllFiles() {
    this.filesArray = [];
    this.fileTarget.value = "";
    this.emptyFileList();
    this.listTarget.classList.add("hidden");
    this.validateFiles();
  }

  updateFileInput(fileNameToRemove) {
    const filesArray = Array.from(this.fileTarget.files);
    const updatedFilesArray = filesArray.filter((file) => !file.name.startsWith(fileNameToRemove));
    const dataTransfer = new DataTransfer();

    updatedFilesArray.forEach((file) => dataTransfer.items.add(file));

    const newInput = this.fileTarget.cloneNode();
    newInput.files = dataTransfer.files;

    this.copyAttributes(this.fileTarget, newInput);

    this.fileTarget.parentNode.replaceChild(newInput, this.fileTarget);

    this.filesArray = updatedFilesArray;
  }

  copyAttributes(source, target) {
    Array.from(source.attributes).forEach((attribute) => {
      target.setAttribute(attribute.name, attribute.value);
    });
  }
}
